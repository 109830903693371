import { EnumTranslation } from '@edgebox/sync-core-data-definitions';
import { FormRow, ValidationError } from '@edgebox/react-components';
import React from 'react';
import Form from 'react-bootstrap/Form';

interface IProps<Enum extends string | number> {
  name: string;
  enumValues: EnumTranslation<Enum>;
  currentValue: Enum;
  setValue: (value: Enum) => void;
}

export default function EnumAsRadios<Enum extends string | number>({ name, enumValues, currentValue, setValue }: IProps<Enum>) {
  const keys = Object.keys(enumValues);

  return (
    <div>
      {keys.map((key) => {
        const enumKey = key as Enum;

        return (
          <Form.Check
            label={<span className="cursor-pointer">{enumValues[enumKey]}</span>}
            key={key}
            type={'radio'}
            id={`${name}-${key}`}
            checked={currentValue == enumKey}
            onChange={() => {
              setValue(enumKey);
            }}
          />
        );
      })}
    </div>
  );
}
