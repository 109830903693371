import { ClientSyndicationUsageSummary } from '@edgebox/sync-core-rest-client';
import { ExternalServiceId, Uuid } from '@edgebox/data-definition-kit';
import React, { useContext, useEffect, useState } from 'react';
import { SyncCoreApiContext } from '../../../../../contexts/SyncCoreApiContext';

function WithMostRecentSyndications({
  children,
  siteUuid,
  entity,
  type,
}: {
  children: (recent?: ClientSyndicationUsageSummary[]) => React.ReactElement;
  siteUuid?: Uuid;
  type: { namespaceMachineName: ExternalServiceId; machineName: ExternalServiceId };
  entity: { remoteUniqueId?: ExternalServiceId; remoteUuid?: Uuid };
}) {
  const api = useContext(SyncCoreApiContext);

  const [recent, setRecent] = useState<ClientSyndicationUsageSummary[] | undefined>(undefined);

  useEffect(() => {
    if (api && siteUuid) {
      api.api.syndication.syndications
        .usageSummaryForSite(
          siteUuid,
          type,
          entity.remoteUniqueId ? { remoteUniqueId: entity.remoteUniqueId } : { remoteUuid: entity.remoteUuid! },
          { includingMigrations: true, isRegularSyndication: true, separateUntil: 3 }
        )
        .then((response) => setRecent(response.items))
        .catch((e) => {});
    }
  }, [siteUuid, type, entity, api]);

  return children(recent);
}

export default WithMostRecentSyndications;
