import { InfoIcon, LeftRightContainer, LoadingBar } from '@edgebox/react-components';
import { IRuntimeSyndicationError, SyncCoreDataDefinitionsEnumTranslator, SyndicationErrorType } from '@edgebox/sync-core-data-definitions';
import { SiteRequestDetails } from '@edgebox/sync-core-rest-client/dist/services/api';
import moment from 'moment';
import React from 'react';
import { Alert, Button, Col, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight';
import { EmbeddedModal } from './EmbeddedModal';
import { UserHtml } from './UserHtml';
import { getDateName } from './FormatDate';

const REQUEST_DETAILS_UNAVAILABLE: 1 = 1;
const REQUEST_DETAILS_FAILED_TO_LOAD: 2 = 2;

interface IOperationErrorBodyState {
  showRequestBody?: boolean;
  showResponseBody?: boolean;
  showRaw?: boolean;
}
interface IOperationErrorBodyProps {
  requestDetails?: SiteRequestDetails | typeof REQUEST_DETAILS_UNAVAILABLE | typeof REQUEST_DETAILS_FAILED_TO_LOAD;
  operation?: IRuntimeSyndicationError;
}
class OperationErrorBody extends React.Component<IOperationErrorBodyProps, IOperationErrorBodyState> {
  render() {
    const { requestDetails, operation } = this.props;
    const showRaw = this.state?.showRaw;
    const showRequestBody = this.state?.showRequestBody;
    const showResponseBody = this.state?.showResponseBody;

    if (requestDetails === undefined) {
      return <LoadingBar />;
    } else if (requestDetails === REQUEST_DETAILS_UNAVAILABLE) {
      return (
        <Alert variant="warning">This request doesn't have any details. This usually happens if the request is older than two weeks.</Alert>
      );
    } else if (requestDetails === REQUEST_DETAILS_FAILED_TO_LOAD) {
      return (
        <Alert variant="warning">
          We were not able to load the request details, please try again. If this happens regularly, please reach out to our support.
        </Alert>
      );
    } else {
      let content: React.ReactNode = undefined;

      if (requestDetails.responseBody) {
        try {
          const json = JSON.parse(requestDetails.responseBody);
          content = (
            <>
              <div className="fw-bold">Response body</div>
              <div>
                {showResponseBody ? (
                  <SyntaxHighlighter language="javascript">{JSON.stringify(json, undefined, 2)}</SyntaxHighlighter>
                ) : (
                  <Button variant="link" onClick={() => this.setState({ showResponseBody: true })}>
                    Show
                  </Button>
                )}
              </div>
            </>
          );
        } catch (e) {
          content = (
            <>
              <LeftRightContainer
                left={<strong>Response body</strong>}
                right={
                  <Form.Check
                    className="cursor-pointer"
                    inline
                    id="show-raw"
                    type="switch"
                    checked={!!showRaw}
                    onChange={() => this.setState({ showRaw: !showRaw })}
                    label="Raw"
                  />
                }
              />
              {/* There is no plain "html", so we use "vbscript-html" instead */}
              {showResponseBody ? (
                showRaw ? (
                  <SyntaxHighlighter language="vbscript-html">{requestDetails.responseBody}</SyntaxHighlighter>
                ) : (
                  <UserHtml dangerousHtml={requestDetails.responseBody} />
                )
              ) : (
                <Button variant="link" onClick={() => this.setState({ showResponseBody: true })}>
                  Show
                </Button>
              )}
            </>
          );
        }
      } else {
        content = (
          <>
            <div>
              <strong>Response body</strong>
            </div>
            <em>None.</em>
          </>
        );
      }

      return (
        <>
          {operation?.errorMessage && <Alert variant="warning">{operation.errorMessage}</Alert>}
          <Tabs defaultActiveKey="request">
            <Tab eventKey="request" title={'Request'}>
              <div className="fw-bold mt-4">Request URL</div>
              <div>
                {requestDetails.requestMethod ? requestDetails.requestMethod : undefined} {requestDetails.requestUrl || <em>None.</em>}
              </div>
              <Row className="mt-2">
                <Col>
                  <div className="fw-bold">Start</div>
                  <div>{requestDetails.requestStart ? moment(requestDetails.requestStart).format('LLL') : <em>Not provided.</em>}</div>
                </Col>
                <Col>
                  <div className="fw-bold">End</div>
                  <div>{requestDetails.requestEnd ? moment(requestDetails.requestEnd).format('LLL') : <em>Not provided.</em>}</div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <div className="fw-bold">Settings</div>
                  <div>Max follow: {requestDetails.requestMaxFollow || <em>Not provided.</em>}</div>
                  <div>Max size: {requestDetails.requestMaxSize || <em>Not provided.</em>}</div>
                  <div>Timeout: {requestDetails.requestTimeout || <em>Not provided.</em>}</div>
                </Col>
                <Col>
                  <div className="fw-bold">Time</div>
                  <div>
                    {requestDetails.requestEnd && requestDetails.requestStart ? (
                      `${requestDetails.requestEnd - requestDetails.requestStart} ms`
                    ) : (
                      <em>Not provided.</em>
                    )}
                  </div>
                </Col>
              </Row>
              <div className="fw-bold mt-2">Request headers</div>
              {requestDetails.requestHeaders ? (
                Object.keys(requestDetails.requestHeaders).map((name) => (
                  <Row key={name}>
                    <Col>{name}</Col>
                    <Col>
                      {requestDetails.requestHeaders[name].map((value: string, index: number) => (
                        <div key={index}>{value}</div>
                      ))}
                    </Col>
                  </Row>
                ))
              ) : (
                <em>Not provided.</em>
              )}
              <div className="fw-bold mt-2">Request body</div>
              <div>
                {requestDetails.requestBody ? (
                  showRequestBody ? (
                    <SyntaxHighlighter language="javascript">
                      {JSON.stringify(JSON.parse(requestDetails.requestBody), undefined, 2)}
                    </SyntaxHighlighter>
                  ) : (
                    <Button variant="link" onClick={() => this.setState({ showRequestBody: true })}>
                      Show
                    </Button>
                  )
                ) : (
                  <em>None.</em>
                )}
              </div>
            </Tab>
            <Tab eventKey="response" title={'Response'}>
              <div className="fw-bold mt-4">Status</div>
              <div>
                {requestDetails.responseStatusCode || requestDetails.responseStatusText ? (
                  <>
                    {requestDetails.responseStatusCode || <em>(no code provided)</em>}{' '}
                    {requestDetails.responseStatusText || <em>(no text provided)</em>}
                    {requestDetails.responseStatusCode === 500 && (
                      <>
                        <div className="text-warning">Please check your site logs for more details.</div>
                      </>
                    )}
                  </>
                ) : (
                  <em>None.</em>
                )}
              </div>
              <div className="fw-bold mt-2">Response headers</div>
              {requestDetails.responseHeaders && Object.keys(requestDetails.responseHeaders).length ? (
                Object.keys(requestDetails.responseHeaders).map((name) => (
                  <Row key={name}>
                    <Col>{name}</Col>
                    <Col>
                      {requestDetails.responseHeaders[name].map((value: string, index: number) => (
                        <div key={index}>{value}</div>
                      ))}
                    </Col>
                  </Row>
                ))
              ) : (
                <em>Not provided.</em>
              )}
              <div className="mt-2">{content}</div>
            </Tab>
          </Tabs>
        </>
      );
    }
  }
}

interface IProps {
  errors: IRuntimeSyndicationError[];
  succeeded?: moment.Moment | true;
  onDetails?: (index: number) => void;
}
interface IState {
  requestDetails?: SiteRequestDetails | typeof REQUEST_DETAILS_UNAVAILABLE | typeof REQUEST_DETAILS_FAILED_TO_LOAD;
}
export class OperationErrorList extends React.Component<IProps, IState> {
  render() {
    const { errors, onDetails, succeeded } = this.props;

    errors.sort((a, b) => b.timestamp - a.timestamp);

    const line = () => (
      <div
        style={{ position: 'relative', width: '15px', height: '100%', display: 'inline-block', marginLeft: '20px', marginRight: '10px' }}
      >
        <div
          style={{
            position: 'absolute',
            left: '0',
            top: '50%',
            borderStyle: 'dashed',
            borderWidth: '2px 0 0 0',
            borderColor: '#ddd',
            width: '100%',
          }}
        ></div>
      </div>
    );

    return (
      <>
        {errors.length > 0 && succeeded ? (
          <Row>
            <Col xs={4} lg={3} className="text-muted">
              {line()}
              <span className="d-inline-block align-top text-muted">
                {succeeded === true ? <em>Latest</em> : moment(succeeded).format('HH:mm:ss.SSS')}
              </span>
            </Col>
            <Col xs={8} lg={9} className="text-success">
              Success
            </Col>
          </Row>
        ) : null}
        {errors.map((error, index) => {
          const showDetailsButton =
            error.requestDetails && onDetails ? (
              <Button variant="link" onClick={() => onDetails(index)}>
                Details
              </Button>
            ) : undefined;

          let message: React.ReactNode = undefined;
          if (error.type === SyndicationErrorType.Timeout) {
            message =
              "The request timed out. This usually happens when either the content is very large / consisting of a lot of entities (especially files) or when there's a general performance issue at the site.";
          } else if (error.type === SyndicationErrorType.InvalidDependency) {
            message = 'The content includes an invalid reference. Maybe it was deleted during syndication.';
          } else if (error.type === SyndicationErrorType.BadResponseCode) {
            message = `Site responded with status code ${error.statusCode}.`;
          } else if (error.type === SyndicationErrorType.SiteMustPoll) {
            message = (
              <>
                Site must poll to finish the request.{' '}
                <InfoIcon>
                  Run <em>drush cspep</em> to poll for requests on this site.
                </InfoIcon>
              </>
            );
          } else if (error.type === SyndicationErrorType.SiteFailedToPoll) {
            message = `Site failed to poll for the request.`;
          } else if (error.type === SyndicationErrorType.BadResponseBody) {
            if (showDetailsButton) {
              message = `Site responded with an invalid response body for status code ${error.statusCode}.`;
            } else {
              message = `Site sent an empty response body for status code ${error.statusCode}.`;
            }
          } else if (error.type === SyndicationErrorType.Request) {
            message = `The request failed.`;
          } else {
            message = 'An unexpected error occurred.';
          }

          return (
            <Row key={index}>
              <Col xs={4} lg={3} className="text-muted">
                {line()}
                <span className="d-inline-block align-top">
                  {getDateName(moment(error.timestamp))}, {moment(error.timestamp).format('HH:mm:ss')}
                  <span className="text-light">{moment(error.timestamp).format('.SSS')}</span>
                </span>
              </Col>
              <Col xs={8} lg={9}>
                {message ? <span className="text-danger">{message}</span> : null} {showDetailsButton}
              </Col>
            </Row>
          );
        })}
      </>
    );
  }
}
