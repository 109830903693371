import React, { useContext, useEffect, useState } from 'react';
import withErrorHandler, { ErrorHandler } from '../../../../foundation/components/WithErrorHandler/WithErrorHandler';
import { ClientRemoteEntityRevisionEntity } from '@edgebox/sync-core-rest-client';
import { InternalId } from '@edgebox/data-definition-kit';
import { SyncCoreApiContext } from '../../../../contexts/SyncCoreApiContext';

type Props = {
  entityId: InternalId;
  children: (entity?: ClientRemoteEntityRevisionEntity) => React.ReactNode;
} & ErrorHandler;

const WithRemoteRevisionEntity = ({ entityId, wrapRequest, children, renderRequest }: Props) => {
  const api = useContext(SyncCoreApiContext);
  const [loading, setLoading] = useState(true);
  const [entity, setEntity] = useState<ClientRemoteEntityRevisionEntity | undefined>(undefined);

  useEffect(() => {
    const fetchEntity = async () => {
      if (api) {
        const res = await wrapRequest(() => api.api.syndication.remoteEntityRevisions.item(entityId));

        setEntity(res);
        setLoading(false);
      }
    };

    if (entityId) {
      fetchEntity();
    }
  }, [entityId]);

  if (loading) {
    return renderRequest();
  }
  return children(entity);
};

export default withErrorHandler(WithRemoteRevisionEntity);
