import React, { useContext, useEffect, useState } from 'react';
import { SyncCoreApiContext } from '../../../../contexts/SyncCoreApiContext';
import withErrorHandler, { ErrorHandler } from '../../../../foundation/components/WithErrorHandler/WithErrorHandler';
import { ClientWebhookEntity } from '@edgebox/sync-core-rest-client/dist/client/Syndication/WebhookService';
import { Alert, Badge, Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { Right } from '@edgebox/react-components';
import WebhookSettings from './components/WebhookSettings';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faHourglassHalf } from '@fortawesome/pro-light-svg-icons/faHourglassHalf';
import { SyncCoreFeatureFlagValue } from '../../../SyncCoreFeatureFlagValue';
import { FEATURE_WEBHOOKS_TRIGGER_LIMIT } from '../../../../features';

type NotificationsProps = ErrorHandler & {};
function Notifications({ wrapRequest, renderRequest }: NotificationsProps) {
  const api = useContext(SyncCoreApiContext);

  const [webhooks, setWebhooks] = useState<ClientWebhookEntity[] | undefined>();

  const [createNew, setCreateNew] = useState(false);

  const [selectedWebhook, setSelectedWebhook] = useState<ClientWebhookEntity | undefined>();

  const fetchData = async () => {
    if (!api) {
      return;
    }

    const webhooks = await wrapRequest(() => {
      return api?.api.syndication.webhooks.list({ itemsPerPage: 100 });
    });
    setWebhooks(webhooks.items);
  };

  useEffect(() => {
    fetchData();
  }, [api]);

  if (!webhooks) {
    return <div>{renderRequest('bar')}</div>;
  }

  if (createNew) {
    return (
      <WebhookSettings
        onDone={(webhook) => {
          if (webhook) {
            setWebhooks([...webhooks, webhook]);
          }
          setCreateNew(false);
        }}
      />
    );
  }

  if (selectedWebhook) {
    return (
      <WebhookSettings
        webhook={selectedWebhook}
        onDone={(webhook, deleted) => {
          if (webhook) {
            setWebhooks([...webhooks.map((candidate) => (candidate.id === selectedWebhook.id ? webhook : candidate))]);
          } else if (deleted) {
            setWebhooks([...webhooks.filter((candidate) => candidate.id !== selectedWebhook.id)]);
          }

          setSelectedWebhook(undefined);
        }}
      />
    );
  }

  return (
    <div>
      <Right className="m-0">
        <Button variant="primary" onClick={() => setCreateNew(true)}>
          Add
        </Button>
      </Right>

      {webhooks.length === 0 ? null : (
        <Alert variant="info" className="m-1 mt-3">
          <SyncCoreFeatureFlagValue featureName={FEATURE_WEBHOOKS_TRIGGER_LIMIT}>
            {(value) => (
              <>
                <strong>Account limit:</strong> Webhooks will trigger no more than {value ?? 30} times per hour.
              </>
            )}
          </SyncCoreFeatureFlagValue>
        </Alert>
      )}

      <div className="mt-3 p-3">
        {webhooks.map((webhook) => {
          let verifiedEmails = webhook.actions.map((c) => c.emailTargets?.filter((c) => c.verified).map((c) => c.email) ?? []).flat();
          verifiedEmails = verifiedEmails.filter((email, index) => verifiedEmails.indexOf(email) === index);

          let pendingEmails = webhook.actions.map((c) => c.emailTargets?.filter((c) => !c.verified).map((c) => c.email) ?? []).flat();
          pendingEmails = pendingEmails.filter((email, index) => pendingEmails.indexOf(email) === index);

          return (
            <div
              key={webhook.id}
              className="d-flex flex-rows mt-2 bg-white-selectable hover-shadow cursor-pointer rounded p-2"
              onClick={() => setSelectedWebhook(webhook)}
            >
              <div className="flex-grow-1 flex-shrink-1 fw-bold">{webhook.name}</div>
              <div className="flex-grow-0 flex-shrink-0">
                {pendingEmails.length > 0 ? (
                  <span className="border border-1 border-warning rounded text-warning d-inline p-1 ps-2 pe-2 me-2">
                    <FontAwesomeIcon icon={faHourglassHalf} className={`text-warning`} /> {pendingEmails.length}
                  </span>
                ) : null}
                {verifiedEmails.length > 0 ? (
                  <span className="border border-1 border-success rounded text-success d-inline p-1 ps-2 pe-2 me-2">
                    <FontAwesomeIcon icon={faCheckCircle} className={`text-success`} /> {verifiedEmails.length}
                  </span>
                ) : null}
              </div>
              <div className="flex-grow-0 flex-shrink-0 pe-2 ps-2 text-dark">
                <FontAwesomeIcon icon={faChevronRight} className="align-middle" />
              </div>
            </div>
          );
        })}
      </div>

      {webhooks.length === 0 ? (
        <Alert variant="info" className="m-1 mt-3">
          You haven't configured any notifications yet.
        </Alert>
      ) : null}
    </div>
  );
}

export default withErrorHandler(Notifications);
