import React, { useContext, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InternalId } from '@edgebox/data-definition-kit';
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog';
import {
  ClientRemoteEntityRevisionEntity,
  ClientRemoteEntityRevisionEntitySummary,
  ClientSyndicationUsageSummary,
} from '@edgebox/sync-core-rest-client';
import { LeftRightSpan } from '@edgebox/react-components';
import { EmbeddedModal } from '../../../../EmbeddedModal';
import { Dropdown, DropdownButton, Modal } from 'react-bootstrap';
import { SerializedEntityRevision } from '../../../../SerializedEntityRevision';
import { DisplayEntityRevision } from '../../../../DisplayEntityRevision';
import { CustomOption } from '../../EntityStatus/EntityStatus';
import { getStyleColors } from '../../../../../Helpers';
import { SyncCoreApiContext } from '../../../../../contexts/SyncCoreApiContext';
import LanguageSelector from './LanguageSelector';
import withErrorHandler, { ErrorHandler } from '../../../../../foundation/components/WithErrorHandler/WithErrorHandler';

type SerializedModeType = 'table' | 'yaml-pretty' | 'yaml-raw';

type SerializeProps = {
  item: ClientRemoteEntityRevisionEntity;
  serializeMode?: SerializedModeType;
  selectedComparison?: number;
  selectedComparisonId?: InternalId;
  handleSerializeModeUpdate: (v: SerializedModeType) => void;
  handleSelectedComparisonUpdate: (selectedComparison?: number, selectedComparisonId?: InternalId) => void;
  onHide: () => void;
  revision: ClientSyndicationUsageSummary[];
} & ErrorHandler;

const Serialize = ({
  item,
  serializeMode,
  selectedComparison, // index of the update
  selectedComparisonId, // default translation id(entity id)
  onHide,
  handleSerializeModeUpdate,
  handleSelectedComparisonUpdate,
  wrapRequest,
  renderRequest,
  revision,
}: SerializeProps) => {
  const { primary, danger } = getStyleColors();

  const api = useContext(SyncCoreApiContext);

  const [entity, setEntity] = useState(item);
  const [selectedComparisonTranslationId, setSelectedComparisonTranslationId] = useState(selectedComparisonId);
  const [languageCode, setLanguageCode] = useState(item.language as string);

  // Initial entity passed
  const initialEntityRef = useRef(item);

  useEffect(() => {
    setEntity(item);
  }, [item]);

  useEffect(() => {
    setSelectedComparisonTranslationId(selectedComparisonId);

    const handleTranslation = async () => {
      if (languageCode == item.language) {
        setSelectedComparisonTranslationId(selectedComparisonId);
      } else {
        if (selectedComparisonId && api) {
          const newTranslations = await wrapRequest(() => api.api.syndication.remoteEntityRevisions.getTranslations(selectedComparisonId));

          const filteredTranslation = newTranslations && newTranslations.items.filter((item) => item.language === languageCode);

          if (filteredTranslation && filteredTranslation?.length > 0) {
            setSelectedComparisonTranslationId(filteredTranslation[0].id);
          } else {
            setSelectedComparisonTranslationId(undefined);
          }
        }
      }
    };

    handleTranslation();
  }, [selectedComparisonId, languageCode, api]);

  const handleLanguageSelect = async (id: InternalId, code: string) => {
    setLanguageCode(code);

    if (api) {
      const entity = await wrapRequest(() => api.api.syndication.remoteEntityRevisions.item(id));
      setEntity(entity);
    }
  };

  return (
    <EmbeddedModal scrollable size="lg" fullscreen show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="w-100" title={entity.name}>
          <LeftRightSpan
            className="w-100"
            left={<span className="text-truncate mw-100">{entity.name || <em>Unnamed</em>}</span>}
            right={
              <div className="d-flex">
                {revision && (
                  <div className="me-2">
                    <Select
                      isClearable
                      value={selectedComparison === undefined ? undefined : revision[selectedComparison]}
                      getOptionValue={(option) => (option.sourceSite ?? option.thisSite ?? option.thisSite).id}
                      getOptionLabel={(option) => option.startedAt.fromNow()}
                      isOptionDisabled={(option) =>
                        !(option.sourceSite ?? option.thisSite ?? option.targetSite)?.rootEntity?.getId() ||
                        (option.sourceSite ?? option.thisSite ?? option.targetSite)?.rootEntity?.getId() === initialEntityRef.current.id
                      }
                      placeholder="Compare to ..."
                      options={revision}
                      onChange={(option) =>
                        handleSelectedComparisonUpdate(
                          !option ? undefined : revision.indexOf(option),
                          !option ? undefined : (option.sourceSite ?? option.thisSite ?? option.targetSite)?.rootEntity?.getId()
                        )
                      }
                      components={{ Option: CustomOption }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary,
                          danger,
                        },
                      })}
                    />
                  </div>
                )}
                <LanguageSelector entity={entity} handleSelect={handleLanguageSelect} selectedLanguage={languageCode} />
                <div>
                  <DropdownButton
                    variant="outline-secondary" // Adjust styling as needed
                    title={<FontAwesomeIcon icon={faCog} className="" />} // Cog icon as the trigger
                    size="lg"
                    onSelect={async (newValue) => {
                      // Check if newValue is not null
                      if (newValue) {
                        handleSerializeModeUpdate(newValue as SerializedModeType);
                      }
                    }}
                    className="me-1 bg-transparent embed-pd__serialize-view-mode-toggle"
                  >
                    <Dropdown.Item eventKey="table" className="px-3 py-2 d-block w-100 text-start fs-5 z-999">
                      Table
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="yaml-pretty" className="px-3 py-2 d-block w-100 text-start fs-5 z-999">
                      Technical
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="yaml-raw" className="px-3 py-2 d-block w-100 text-start fs-5 z-999">
                      Raw
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
            }
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {renderRequest()}
        {!serializeMode || serializeMode === 'table' ? (
          <DisplayEntityRevision
            id={entity.id}
            comparisonId={selectedComparisonTranslationId}
            key={entity.id + '-' + selectedComparisonTranslationId}
            hasRemoved={selectedComparisonId && selectedComparisonTranslationId === undefined}
          />
        ) : (
          <SerializedEntityRevision
            id={entity.id}
            comparisonId={selectedComparisonTranslationId}
            structure={serializeMode === 'yaml-raw' ? 'plain' : 'pretty'}
            values={serializeMode === 'yaml-raw' ? 'plain' : 'pretty'}
          />
        )}
      </Modal.Body>
    </EmbeddedModal>
  );
};

export default withErrorHandler(Serialize);
