import * as Sentry from '@sentry/react';
import React from 'react';
import { Route, Routes } from 'react-router';
import { ContentCountCustomer } from './Box/ContentCountCustomer';
import { UpdateStatusBox } from './Box/UpdateStatusBox';
import { EntityStatus } from './Embed/EntityStatus/EntityStatus';
import { FlowForm } from './Embed/FlowForm';
import { Migrate } from './Embed/Migrate';
import { PullDashboard } from './Embed/PullDashboard/PullDashboard';
import { RegisteredSite } from './Embed/RegisteredSite';
import { RegisterSite } from './Embed/RegisterSite';
import { SyndicationDashboard } from './Embed/SyndicationDashboard';
import { NotFound } from './NotFound';
import { SiteSettings } from './Embed/SiteSettings';
import Notifications from './Embed/Notifications/Notifications';
import { SyncCoreFeatureFlagGate } from '../SyncCoreFeatureFlagGate';
import { FEATURE_WEBHOOKS_AVAILABLE } from '../../features';
import { Alert } from 'react-bootstrap';

export function RoutingContainer() {
  return (
    <>
      <div>
        <Sentry.ErrorBoundary>
          <Routes>
            <Route path="migrate" element={<Migrate />} />
            <Route path="syndication-dashboard" element={<SyndicationDashboard />} />
            <Route path="register-site" element={<RegisterSite />} />
            <Route path="site-registered" element={<RegisteredSite />} />
            <Route path="site-settings" element={<SiteSettings />} />
            <Route path="flow-form" element={<FlowForm />} />

            <Route path="entity-status" element={<EntityStatus />} />
            <Route path="pull-dashboard" element={<PullDashboard />} />

            <Route
              path="Notifications"
              element={
                <SyncCoreFeatureFlagGate
                  featureName={FEATURE_WEBHOOKS_AVAILABLE}
                  ifEnabled={() => <Notifications />}
                  ifDisabled={() => <Alert variant="info">Please update your Sync Core to make use of notifications.</Alert>}
                />
              }
            />

            <Route path="box">
              <Route path="content-count/customer" element={<ContentCountCustomer />} />
              <Route path="update-status" element={<UpdateStatusBox />} />
            </Route>

            <Route element={<NotFound />} />
          </Routes>
        </Sentry.ErrorBoundary>
      </div>
    </>
  );
}
