import { FeatureSummary } from '@edgebox/sync-core-rest-client';
import React from 'react';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../services/SyncCoreApiComponent';

interface IProps {
  featureName: string;
  children: (value: number | null) => React.ReactNode;
}

interface IState extends ISyncCoreApiComponentState {
  flags?: FeatureSummary;
}

export class SyncCoreFeatureFlagValue extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    const flags = await this.api.utility.features.summary();
    return {
      flags,
    };
  }

  render() {
    const { featureName, children } = this.props;
    const { flags } = this.state;
    if (!flags) {
      return this.renderRequest();
    }

    return children(flags[featureName] ?? null);
  }
}
