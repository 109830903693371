import React, { useState } from 'react';
import { Request, RequestLoadingAnimation } from '../../../services/Request';
import { ClientError } from '@edgebox/data-definition-kit';

export interface ErrorHandler {
  wrapRequest<T>(request: () => Promise<T>): T;
  renderRequest(v?: RequestLoadingAnimation): React.ReactNode;
}

function withErrorHandler<Props>(WrappedComponent: React.ComponentType<Props & ErrorHandler>): React.ComponentType<Props> {
  return function WithError(props: any) {
    const [error, setError] = useState<ClientError | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const handleRequest = async <T,>(request: () => Promise<T>): Promise<T | undefined> => {
      try {
        setIsLoading(true);
        setError(undefined);
        const result = await request();

        return result;
      } catch (err) {
        console.error(err, { ...(err as any) });
        setError(err as ClientError);
      } finally {
        setIsLoading(false);
      }
    };

    const renderRequest = (loadingAnimation?: RequestLoadingAnimation) => {
      return <Request error={error} showUnauthorizedModal={false} loadingAnimation={isLoading ? loadingAnimation : 'none'} />;
    };

    return (
      <>
        <WrappedComponent {...props} wrapRequest={handleRequest} renderRequest={renderRequest} />
      </>
    );
  };
}

export default withErrorHandler;
