import withErrorHandler, { ErrorHandler } from '../../../../../foundation/components/WithErrorHandler/WithErrorHandler';
import { useCallback, useContext, useEffect, useState } from 'react';
import { SyncCoreApiContext } from '../../../../../contexts/SyncCoreApiContext';
import { ClientWebhookDraft, ClientWebhookEntity } from '@edgebox/sync-core-rest-client/dist/client/Syndication/WebhookService';
import React from 'react';
import { Button, Form, InputGroup, Row } from 'react-bootstrap';
import { ContentCol, HeaderCol, Right } from '@edgebox/react-components';
import {
  SyncCoreDataDefinitionsEnumTranslator,
  SyndicationStatus,
  SyndicationType,
  WebhookActionIntervals,
  WebhookActionType,
  WebhookEntityType,
  WebhookFilterType,
  WebhookStandardEmail,
} from '@edgebox/sync-core-data-definitions';
import { ClientSiteEntity } from '@edgebox/sync-core-rest-client';
import { getCurrentSiteUuid, getUserFromJwt } from '../../../../../services/SyncCoreApiComponent';
import moment from 'moment';
import EnumAsRadios from '../../../../EnumAsRadios';
import { isEmail, validateSync } from 'class-validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faHourglassHalf } from '@fortawesome/pro-light-svg-icons/faHourglassHalf';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons/faTrashCan';

const HEADER_COL_SIZE = 3;

// This ensures we're covering all types, even as new ones are added later.
const ENTITY_BASED_SYNDICATION_TYPES_MAP: { [key in SyndicationType]: boolean } = {
  [SyndicationType.PushEntity]: true,
  [SyndicationType.RetrieveAndPushEntity]: true,
  [SyndicationType.RetrieveEntity]: true,
  [SyndicationType.DeleteEntity]: true,
  [SyndicationType.RetrieveAndDeleteEntity]: true,
  [SyndicationType.DeleteEmbeddedEntity]: true,
  [SyndicationType.RetrieveAndDeleteEmbeddedEntity]: true,
  [SyndicationType.RetrieveEntityList]: true,
  [SyndicationType.RetrieveConfig]: false,
  [SyndicationType.TriggerWebhook]: false,
};
const ENTITY_BASED_SYNDICATION_TYPES = Object.entries(ENTITY_BASED_SYNDICATION_TYPES_MAP)
  .filter(([, value]) => value)
  .map(([type]) => type as SyndicationType);

type WebhookSettingsProps = ErrorHandler & {
  webhook?: ClientWebhookEntity;
  onDone?: (webhook?: ClientWebhookEntity, deleted?: boolean) => void;
};
function WebhookSettings({ wrapRequest, renderRequest, webhook: _webhook, onDone }: WebhookSettingsProps) {
  const api = useContext(SyncCoreApiContext);

  const [webhook, setWebhook] = useState<ClientWebhookEntity | ClientWebhookDraft>(
    _webhook ??
      new ClientWebhookDraft({
        actions: [
          {
            id: `${WebhookActionType.Email}-${Date.now()}`,
            type: WebhookActionType.Email,
            interval: WebhookActionIntervals.EveryHour,
            limitPerHour: 1_000,
            standardEmail: WebhookStandardEmail.SyndicationFailed,
            emailTargets: [
              {
                email: api ? (getUserFromJwt(api.jwt)?.email ?? '') : '',
                verified: false,
                verificationSentAt: moment(),
              },
            ],
          },
        ],
        filter: {
          type: WebhookFilterType.LogicalAnd,
          filter: [
            {
              type: WebhookFilterType.PropertyValueIn,
              property: 'status',
              value: [SyndicationStatus.Failed],
            },
            {
              type: WebhookFilterType.PropertyValueIn,
              property: 'type',
              value: ENTITY_BASED_SYNDICATION_TYPES,
            },
          ],
        },
        customer: null as any,
        entityType: WebhookEntityType.Syndication,
        name: 'Updates Failed',
        project: null as any,
      })
  );

  const [originalEmails] = useState(webhook.actions.map((c) => c.emailTargets?.map((c) => c.email) ?? []).flat());

  const [verifiedEmails] = useState(
    webhook.actions.map((c) => c.emailTargets?.map((c) => (c.verified ? c.email : undefined)) ?? []).flat()
  );

  const [sentVerificationEmails, setSentVerificationEmails] = useState<string[]>([]);

  const isSaved = webhook instanceof ClientWebhookEntity;
  const [isSaving, setIsSaving] = useState(false);

  const saveAsync = async () => {
    setIsSaving(true);

    const savedWebhook = await wrapRequest(() => {
      if (isSaved) {
        return api!.api.syndication.webhooks.update(webhook);
      }

      return api!.api.syndication.webhooks.create(webhook);
    });

    setIsSaving(false);

    if (savedWebhook) {
      setWebhook(savedWebhook);

      if (onDone) {
        onDone(savedWebhook);
      }
    }
  };
  const save = useCallback(() => {
    saveAsync();
  }, [webhook]);

  const [site, setSite] = useState<ClientSiteEntity | undefined>();
  const loadSite = async () => {
    const site = await wrapRequest(async () => {
      const siteUuid = getCurrentSiteUuid(api?.jwt);
      if (!siteUuid) {
        throw new Error('Missing site');
      }
      return api?.api.billing.sites.itemByUuid(siteUuid);
    });

    if (site && !isSaved) {
      const withNamespace = new ClientWebhookDraft({ ...webhook, customer: site.customer, project: site.project });
      setWebhook(withNamespace);
    }

    setSite(site);
  };
  useEffect(() => {
    loadSite();
  }, []);

  if (!site) {
    return <div>{renderRequest('bar')}</div>;
  }

  const validationErrors: string[] = [];
  const validationErrorElements: { [key: string]: string } = {};
  if (!webhook.name) {
    validationErrors.push((validationErrorElements.name = 'Please provide a name to continue.'));
  }

  const standardValidationErrors = validateSync(
    plainToInstance(isSaved ? ClientWebhookEntity : ClientWebhookDraft, instanceToPlain(webhook))
  );
  standardValidationErrors.length && console.log('Webhook validation error', standardValidationErrors);

  return (
    <div>
      {renderRequest('bar')}

      <Row>
        <HeaderCol xs={HEADER_COL_SIZE}>Name</HeaderCol>
        <ContentCol>
          <Form.Control
            type="text"
            placeholder="Enter a name..."
            value={webhook.name}
            className={validationErrorElements.name ? 'is-invalid' : 'is-valid'}
            onChange={(e) => {
              const name = e.target.value || '';
              setWebhook(isSaved ? new ClientWebhookEntity({ ...webhook, name }) : new ClientWebhookDraft({ ...webhook, name }));
            }}
          />
        </ContentCol>
      </Row>

      <Row>
        <HeaderCol xs={HEADER_COL_SIZE}>Actions</HeaderCol>
        <ContentCol>
          {webhook.actions.map((action, index) => {
            return (
              <div key={action.id}>
                <label className="fw-bold">Interval</label>
                <EnumAsRadios<WebhookActionIntervals>
                  name="interval"
                  currentValue={action.interval}
                  enumValues={SyncCoreDataDefinitionsEnumTranslator.translateEnum('WebhookActionIntervals') as any}
                  setValue={(interval) => {
                    const actions = [
                      ...webhook.actions.map((currentAction, currentActionIndex) =>
                        currentActionIndex === index
                          ? { ...currentAction, interval: typeof interval === 'string' ? parseInt(interval) : interval }
                          : currentAction
                      ),
                    ];
                    setWebhook(
                      isSaved ? new ClientWebhookEntity({ ...webhook, actions }) : new ClientWebhookDraft({ ...webhook, actions })
                    );
                  }}
                />

                <label className="fw-bold">Emails</label>
                {action.emailTargets?.map((emailTarget, emailTargetIndex) => {
                  const verified = verifiedEmails.includes(emailTarget.email);
                  return (
                    <div key={emailTargetIndex} className="d-flex">
                      <div className="w-50 flex-grow-0 flex-shrink-0">
                        <InputGroup className="mb-3">
                          <InputGroup.Text
                            id={`action-${index}-email-${emailTargetIndex}-verified`}
                            title={verified ? 'Verified' : 'Verification pending'}
                          >
                            <FontAwesomeIcon
                              icon={verified ? faCheckCircle : faHourglassHalf}
                              className={`text-${verified ? 'success' : 'dark'}`}
                            />
                          </InputGroup.Text>
                          <Form.Control
                            type="email"
                            placeholder="Enter an email address..."
                            value={emailTarget.email}
                            className={isEmail(emailTarget.email) ? 'is-valid' : 'is-invalid'}
                            onChange={(e) => {
                              const email = e.target.value || '';
                              const emailTargets = action.emailTargets!.map((currentEmailTarget, currentEmailTargetIndex) =>
                                currentEmailTargetIndex === emailTargetIndex ? { ...currentEmailTarget, email } : currentEmailTarget
                              );
                              const actions = [
                                ...webhook.actions.map((currentAction, currentActionIndex) =>
                                  currentActionIndex === index ? { ...currentAction, emailTargets } : currentAction
                                ),
                              ];
                              setWebhook(
                                isSaved ? new ClientWebhookEntity({ ...webhook, actions }) : new ClientWebhookDraft({ ...webhook, actions })
                              );
                            }}
                          />
                        </InputGroup>
                      </div>
                      <div className="w-50 flex-grow-0 flex-shrink-0 ps-2">
                        {action.emailTargets!.length > 1 ? (
                          <Button
                            variant="danger"
                            onClick={() => {
                              const emailTargets = action.emailTargets!.filter(
                                (currentEmailTarget, currentEmailTargetIndex) => currentEmailTargetIndex !== emailTargetIndex
                              );
                              const actions = [
                                ...webhook.actions.map((currentAction, currentActionIndex) =>
                                  currentActionIndex === index ? { ...currentAction, emailTargets } : currentAction
                                ),
                              ];
                              setWebhook(
                                isSaved ? new ClientWebhookEntity({ ...webhook, actions }) : new ClientWebhookDraft({ ...webhook, actions })
                              );
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashCan} className={`text-danger`} />
                          </Button>
                        ) : null}

                        {isSaved && !emailTarget.verified && originalEmails.includes(emailTarget.email) ? (
                          !sentVerificationEmails.includes(emailTarget.email) ? (
                            <Button
                              variant="link"
                              onClick={async () => {
                                setIsSaving(true);

                                const sent = await wrapRequest(() =>
                                  api!.api.syndication.webhooks.sendVerificationEmail(webhook.id, emailTarget.email)
                                );

                                setIsSaving(false);

                                if (sent?.success) {
                                  setSentVerificationEmails([...sentVerificationEmails, emailTarget.email]);
                                }
                              }}
                            >
                              Resend verification email
                            </Button>
                          ) : (
                            <em>Verification email sent.</em>
                          )
                        ) : null}
                      </div>
                    </div>
                  );
                })}
                <div>
                  <Button
                    variant="link"
                    onClick={() => {
                      const emailTargets = [
                        ...action.emailTargets!,
                        {
                          email: '',
                          verified: false,
                          verificationSentAt: moment(),
                        },
                      ];
                      const actions = [
                        ...webhook.actions.map((currentAction, currentActionIndex) =>
                          currentActionIndex === index ? { ...currentAction, emailTargets } : currentAction
                        ),
                      ];
                      setWebhook(
                        isSaved ? new ClientWebhookEntity({ ...webhook, actions }) : new ClientWebhookDraft({ ...webhook, actions })
                      );
                    }}
                  >
                    Add email
                  </Button>
                </div>
              </div>
            );
          })}
        </ContentCol>
      </Row>

      <Right className="m-0 mt-3">
        <Button variant="light" disabled={isSaving} onClick={() => onDone?.()}>
          Cancel
        </Button>
        {isSaved ? (
          <Button
            variant="danger"
            className="text-capitalize"
            disabled={isSaving}
            onClick={async () => {
              setIsSaving(true);
              const deleted = await wrapRequest(async () => {
                return await api!.api.syndication.webhooks.delete(webhook.id);
              });
              setIsSaving(false);

              if (deleted.success) {
                onDone?.(undefined, true);
              }
            }}
          >
            Delete
          </Button>
        ) : null}
        <Button variant="primary" disabled={!!standardValidationErrors.length || isSaving} onClick={save}>
          Save
        </Button>
      </Right>
    </div>
  );
}

export default withErrorHandler(WebhookSettings);
